import React, { memo } from "react";

import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import PriceCalculator from "../../Pages/PriceCalculator";
import VideoPriceCalculator from "../../Pages/PriceCalculator/videoPriceCalculator";
import DoorLockPriceCalculator from "../../Pages/PriceCalculator/doorLockPriceCalculator";
import Checkout from "../Checkout_Price_Calc/Checkout";
const PriceCalculatorEmbeded = (props) => {
  const { className } = props;
  return (
    <m.section
      // grid-cols-[34%_66%]

      className="bg-darkblue-grey text-white grid  grid-price-calc-embeded  md:grid-cols-1"
      style={{ fontSize: "normal" }}
    >
      <Container>
        {/* <div className="p-24 priceCalcEmbeded"> */}
        {/* <Row> */}
        <div className=" priceCalcEmbeded">
          <h4>
            {props.h1
              ? props.h1
              : " Lassen Sie uns gemeinsam für Ihre Sicherheit sorgen."}
          </h4>
          <h5 className="subheader text-white">
            {" "}
            {props.h2
              ? props.h2
              : "Starten Sie jetzt Ihren Weg zu einem neuen Sicherheitsgefühl."}
          </h5>
          <p
            className="text-pink "
            style={{
              textDecoration: "",
              lineHeight: "175%",
              letterSpacing: "2px",
              fontWeight: "500",
              fontSize: "1.55rem",
            }}
          >
            {props.h3
              ? props.h3
              : "Jetzt Preisberechnung für eine Alarmanlage hier starten!"}
          </p>
        </div>
        {/* </Row> */}
      </Container>
      <div className="p-2  float-right">
        {props.type == "intrusion" ? (
          <PriceCalculator
            embeded={true}
            shaddow={true}
            className={className}
          />
        ) : (
          ""
        )}
        {props.type == "video" ? (
          <VideoPriceCalculator embeded={true} shaddow={true} />
        ) : (
          ""
        )}
        {props.type == "door_lock" ? (
          <DoorLockPriceCalculator embeded={true} shaddow={true} />
        ) : (
          ""
        )}
        {props.type == "kunden_wer_kunden" ? (
          <Checkout
            technicalSource="Contact Form"
            marketingSource="Customers-recruit-Customers"
          />
        ) : (
          ""
        )}
        {props.type == "Kooperationspartner" ? (
          <Checkout
            technicalSource="Contact Form"
            marketingSource="Lead-Partner"
          />
        ) : (
          ""
        )}
      </div>
    </m.section>
  );
};

export default memo(PriceCalculatorEmbeded);
