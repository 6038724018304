import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { m } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Buttons from "@Components/Button/Buttons";
import { fadeIn, fadeInLeft } from "../../Functions/GlobalAnimations";
import TrustAusgezeichnet from "../../Assets/img/Homepage/PC3-Trust-Ausgezeichnet-Homepage.webp";
import TrustMeisterbetrieb from "../../Assets/img/Homepage/PC2-Trust-Meisterbetrieb-Homepage.webp";
import TrustGoogle from "../../Assets/img/Homepage/PC1-Trust-Google-Homepage.webp";
import Ausgezeichnet from "../../Assets/img/Homepage/01-Big-Hero-Trust-Ausgezeichnet-Homepage.webp";

// Properties Images
import IMG_HAUS from "../../Assets/img/price_calc/001 Haus.svg";
import IMG_APPARTMENT from "../../Assets/img/price_calc/001 Wohnung.svg";
import IMG_BUSINESS from "../../Assets/img/price_calc/001 Gewerbe.svg";
import IMG_OFFICE from "../../Assets/img/price_calc/001 Buero.svg";

// WhichAreaWantToSecure  images
import WhichAreaWantToSecure1 from "../../Assets/img/price_calc/Video/002a-Innenbereich.svg";
import WhichAreaWantToSecure2 from "../../Assets/img/price_calc/Video/002b-Außenbereich.svg";
import WhichAreaWantToSecure3 from "../../Assets/img/price_calc/Video/002c-Innen-und-Aussenbereich.svg";
import IDK from "../../Assets/img/price_calc/Video/0XXd-Weiss-nicht.svg";
// WhichCameraShape images
import WhichCameraShape1 from "../../Assets/img/price_calc/Video/003a-Bullet-Kamera.svg";
import WhichCameraShape2 from "../../Assets/img/price_calc/Video/003b-Dome-Kamera.svg";
import WhichCameraShape3 from "../../Assets/img/price_calc/Video/003c-Bullet-und-Dome-Kamera.svg";
// CameraResultion images
import CameraResultion1 from "../../Assets/img/price_calc/Video/004a-HD.svg";
import CameraResultion2 from "../../Assets/img/price_calc/Video/004b-4MP.svg";
import CameraResultion3 from "../../Assets/img/price_calc/Video/004c-4K-UHD.svg";
// ShouldArtificialIntelligence images
import ShouldArtificialIntelligence1 from "../../Assets/img/price_calc/Video/005a-KI-Kamera.svg";
import ShouldArtificialIntelligence2 from "../../Assets/img/price_calc/Video/0XXc-Nein.svg";

// Extentions images
import EXTENTIONS1 from "../../Assets/img/price_calc/Video/007a-Alarmanlage.svg";
import EXTENTIONS2 from "../../Assets/img/price_calc/582 Elektronisches Türschloss.svg";
import EXTENTIONS3 from "../../Assets/img/price_calc/581 Brandmelder.svg";

import QUESTION_MARK from "../../Assets/img/price_calc/000 Weiss nicht.svg";

import NO_CROSS from "../../Assets/img/price_calc/000 Nein.svg";
import SMARTPHONE_VIDEO from "../../Assets/img/price_calc/208 Smartphone Video.svg";
import CHECKOUT1 from "../../Assets/img/price_calc/210527 Screenshot Sicherheitskonzept.webp";
import CHECKOUT2 from "../../Assets/img/price_calc/220322_DIN_Logo.webp";
import THANK_YOU from "../../Assets/img/price_calc/image_2_kostenlose_beratung.svg";

const API_URL = "https://api.ma-development.de";
// const PC_DATA = {
//   properties: {
//     name: "objectType",
//     question: "Which property do you want to secure?",
//     options: [
//       // Haus,Wohnung,Gewerbe,Buero
//       {
//         name: "House",
//         value: "Haus",
//         img: IMG_HAUS,
//       },
//       { name: "Appartment", value: "Wohnung", img: IMG_APPARTMENT },
//       { name: "Business", value: "Gewerbe", img: IMG_BUSINESS },
//       { name: "Office", value: "Buero", img: IMG_OFFICE },
//     ],
//   },
//   WhichAreaWantToSecure: {
//     name: "cctv",
//     question: "Which areas do you want to monitor via video?",

//     options: [
//       { name: "Interior", value: "Innenbereich", img: WhichAreaWantToSecure1 },
//       {
//         name: "Outdoor area",
//         value: "Außenbereich",
//         img: WhichAreaWantToSecure2,
//       },
//       {
//         name: "Indoor & Outdoor",
//         value: "Innen- & Außenbereich",
//         img: WhichAreaWantToSecure3,
//       },
//       { name: "I do not know", value: "Weiß nicht", img: HOW_MANY_FLOORS1 },
//     ],
//   },
//   HowManyCameras: {
//     name: "cctv_count",
//     question: "How many video cameras do you need?",
//   },

//   WhichCameraShape: {
//     name: "cctv-type",
//     question: "Which camera shape would you prefer?",
//     options: [
//       {
//         name: "Bullet cameras",
//         value: "Bulletkameras",
//         img: WhichCameraShape1,
//       },
//       { name: "Dome Cameras", value: "Domekameras", img: WhichCameraShape2 },
//       {
//         name: "Bullet & Dome Cameras",
//         value: "Bullet- & Domekameras",
//         img: WhichCameraShape3,
//       },
//       { name: "I do not know", value: "Weiß nicht", img: IDK },
//     ],
//   },
//   ShouldArtificialIntelligence: {
//     name: "kfw",
//     question: "Should your cameras be equipped with artificial intelligence?",
//     questionInfo:
//       "Automatic analysis of dangerous situations (e.g. detection of people and vehicles)",
//     options: [
//       { name: "Yes", value: "ja", img: ShouldArtificialIntelligence1 },
//       { name: "No", value: "Nein", img: ShouldArtificialIntelligence2 },

//       { name: "I do not know", value: "Weiß ich nicht", img: IDK },
//     ],
//   },
//   CameraResultion: {
//     name: "cctv_resolution",
//     question: "How high should the resolution of the cameras be?",
//     options: [
//       { name: "Default", value: "Standard", img: CameraResultion1 },
//       { name: "High", value: "4 Megapixel", img: CameraResultion2 },
//       {
//         name: "Very High",
//         value: "8 Megapixel (4K UHD Qualität)",
//         img: CameraResultion3,
//       },
//       { name: "I do not know", value: "Weiß nicht", img: IDK },
//     ],
//   },
//   AccessViaSmartPhone: {
//     name: "smartphone",
//     question:
//       "Would you like to be able to access live recordings via a smartphone app?",
//     options: [
//       { name: "Yes", value: "Ja", img: SMARTPHONE_VIDEO },
//       { name: "No", value: "Nein", img: NO_CROSS },
//       { name: "I do not know", value: "Weiß ich nicht", img: QUESTION_MARK },
//     ],
//   },
//   Extentions: {
//     name: "crossSelling",
//     question: "Are you interested in the following extensions?",
//     options: [
//       { name: "Alarm System", value: "Alarmanlage", img: EXTENTIONS1 },
//       {
//         name: "Electronic door locks",
//         value: "Schließanlage",
//         img: EXTENTIONS2,
//       },
//       {
//         name: "Fire alarm system",
//         value: "Brandmeldeanlage",
//         img: EXTENTIONS3,
//       },
//     ],
//   },
// };

const PC_DATA = {
  properties: {
    name: "objectType",
    question: "Welche Immobilie möchten Sie sichern?",
    options: [
      // Haus,Wohnung,Gewerbe,Buero
      {
        name: "Haus",
        value: "Haus",
        img: IMG_HAUS,
      },
      { name: "Wohnung", value: "Wohnung", img: IMG_APPARTMENT },
      { name: "Gewerbe", value: "Gewerbe", img: IMG_BUSINESS },
      { name: "Büro", value: "Buero", img: IMG_OFFICE },
    ],
  },
  WhichAreaWantToSecure: {
    name: "cctv",
    question: "Welche Bereiche möchten Sie videoüberwachen?",

    options: [
      {
        name: "Innenbereich",
        value: "Innenbereich",
        img: WhichAreaWantToSecure1,
      },
      {
        name: "Außenbereich",
        value: "Außenbereich",
        img: WhichAreaWantToSecure2,
      },
      {
        name: "Innen- & Außenbereich",
        value: "Innen- & Außenbereich",
        img: WhichAreaWantToSecure3,
      },
      { name: "Weiß nicht", value: "Weiß nicht", img: IDK },
    ],
  },
  HowManyCameras: {
    name: "cctv_count",
    question: "Wie viele Videokameras benötigen Sie?",
  },

  WhichCameraShape: {
    name: "cctv-type",
    question: "Welche Kameraform würden Sie bevorzugen?",
    options: [
      {
        name: "Bulletkameras",
        value: "Bulletkameras",
        img: WhichCameraShape1,
      },
      { name: "Domekameras", value: "Domekameras", img: WhichCameraShape2 },
      {
        name: "Bullet- & Domekameras",
        value: "Bullet- & Domekameras",
        img: WhichCameraShape3,
      },
      { name: "Weiß nicht", value: "Weiß nicht", img: IDK },
    ],
  },
  ShouldArtificialIntelligence: {
    name: "kfw",
    question:
      "Sollen Ihre Kameras mit künstlicher Intelligenz ausgestattet sein?",
    questionInfo:
      "Automatische Analyse von Gefahrensituationen (z.B. Erkennung von Menschen und Fahrzeugen)",
    options: [
      { name: "Ja", value: "ja", img: ShouldArtificialIntelligence1 },
      { name: "Nein", value: "Nein", img: ShouldArtificialIntelligence2 },

      { name: "Weiß nicht", value: "Weiß ich nicht", img: IDK },
    ],
  },
  CameraResultion: {
    name: "cctv_resolution",
    question: "Wie hoch sollte die Auflösung der Kameras sein?",
    options: [
      { name: "Standard", value: "Standard", img: CameraResultion1 },
      { name: "Hoch", value: "4 Megapixel", img: CameraResultion2 },
      {
        name: "Sehr hoch",
        value: "8 Megapixel (4K UHD Qualität)",
        img: CameraResultion3,
      },
      { name: "Weiß nicht", value: "Weiß nicht", img: IDK },
    ],
  },
  AccessViaSmartPhone: {
    name: "smartphone",
    question:
      "Möchten Sie gerne per Smartphone App auf Live-Aufnahmen zugreifen können?",
    options: [
      { name: "Ja", value: "Ja", img: SMARTPHONE_VIDEO },
      { name: "Nein", value: "Nein", img: NO_CROSS },
      { name: "Weiß nicht", value: "Weiß ich nicht", img: QUESTION_MARK },
    ],
  },
  Extentions: {
    name: "crossSelling",
    question: "Haben Sie Interesse an folgenden Erweiterungen?",
    questionInfo: "Mehrfachauswahl möglich",
    options: [
      { name: "Alarmanlage", value: "Alarmanlage", img: EXTENTIONS1 },
      {
        name: "Elektronische Türschlösser",
        value: "Schließanlage",
        img: EXTENTIONS2,
      },
      {
        name: "Brandmeldeanlage",
        value: "Brandmeldeanlage",
        img: EXTENTIONS3,
      },
    ],
  },
};
let slideNum = -1;
let phone = 0;
const VideoPriceCalculator = (propsMain) => {
  const { embeded, className, shaddow } = propsMain;
  const [Form, setForm] = useState({
    objectType: "Haus",
    floors: "",
    WhichCameraShape: "",
    CameraResultion: "",
    smartPhone: "",
    extentions: "",
    crossSelling: [],
  });
  const [Slide, setSlide] = useState(-1);
  const [Progress, setProgress] = useState(0);
  const [displayDonKnow, setDisplayDonKnow] = useState(false);
  const [isThankYou, setIsThankYou] = useState(false);
  const props = {
    handleInputChange,
    Form,
    setForm,
    nextPriceCard,
    animate: fadeIn,
  };
  const propsSelect = { handleSelectChange, Form, nextPriceCard };
  // const userFormprops = { User, setUser };
  const PC_FLOW = {
    Haus: [
      <WhichAreaWantToSecure {...props} />,
      <HowManyCameras {...propsSelect} />,
      <WhichCameraShape {...props} />,
      <CameraResultion {...props} />,
      <ShouldArtificialIntelligence {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],

    Wohnung: [
      <WhichAreaWantToSecure {...props} />,
      <HowManyCameras {...propsSelect} />,
      <WhichCameraShape {...props} />,
      <CameraResultion {...props} />,
      <ShouldArtificialIntelligence {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
    Gewerbe: [
      <WhichAreaWantToSecure {...props} />,
      <HowManyCameras {...propsSelect} />,
      <WhichCameraShape {...props} />,
      <CameraResultion {...props} />,
      <ShouldArtificialIntelligence {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
    Buero: [
      <WhichAreaWantToSecure {...props} />,
      <HowManyCameras {...propsSelect} />,
      <WhichCameraShape {...props} />,
      <CameraResultion {...props} />,
      <ShouldArtificialIntelligence {...props} />,
      <AccessViaSmartPhone {...props} />,
      <Extentions {...props} />,
      <UserInfoForm {...props} />,
      <ThankYou />,
    ],
  };

  const VideoPriceCalculatorOutlet = () => {
    if (Slide === -1) {
      return <PropertiesList {...props} className="animate-blink" />;
    }
    const Flow = PC_FLOW[Form.objectType][Slide] ?? (
      <PropertiesList {...props} className="animate-blink" />
    );
    return <div className="animate-blink">{Flow}</div>;
  };

  function handleInputChange(FormData) {
    console.log("FormData", FormData);
    const { name, value } = FormData;
    setForm({ ...Form, ...{ [name]: value } });
    nextPriceCard();
    console.log("Form", Form);
  }
  function handleSelectChange(e) {
    console.log("event", e);
    const { name, value } = e.target;
    setForm({ ...Form, ...{ [name]: value } });
    console.log("Form", Form);
  }

  function trusSymbolClases() {
    let trustImgClasses =
      !PC_FLOW[Form.objectType].length - 2 == Slide ||
      !PC_FLOW[Form.objectType].length - 1 == Slide
        ? "trust-imgs"
        : " trust-imgs mt-48 mt-7rem";
    if (window.innerWidth >= 768) {
      trustImgClasses = "trust-imgs mt-48 mt-7rem";
    }
    if (Progress == 100) {
      trustImgClasses = "trust-imgs mt-12";
    }
    return trustImgClasses;
  }
  function displayIdontKnow() {
    if (slideNum == 1 || slideNum == 6) {
      setDisplayDonKnow(true);
    } else {
      setDisplayDonKnow(false);
    }
  }
  function isThankYouFun() {
    if (slideNum == 8) {
      setIsThankYou(true);
    } else {
      setIsThankYou(false);
    }
  }

  function nextPriceCard(e) {
    if (!PC_FLOW[Form.objectType][Slide + 1]) {
      return;
    }
    slideNum = slideNum + 1;
    setSlide(Slide + 1);
    console.log("slide", Slide);
    console.log("slideNum", slideNum);

    displayIdontKnow();
    isThankYouFun();
  }
  function prevPriceCard(e) {
    if (Slide === -1) {
      return;
    }
    slideNum = slideNum - 1;
    setSlide(Slide - 1);
    console.log("slide", Slide);
    console.log("slideNum", slideNum);

    displayIdontKnow();
  }
  function ProgressBarCom() {
    const ComponentsLength = Form.objectType
      ? PC_FLOW[Form.objectType].length
      : 10;
    // console.log("ComponentsLength", ComponentsLength);
    const now = (((Slide + 1) / (ComponentsLength - 1)) * 100).toFixed();
    // console.log("now", now);
    setProgress(now);
    return (
      <m.section>
        <div className="flex justify-between mb-1">
          <span></span>
          <span className="text-sm font-medium float-right">{now}%</span>
        </div>
        {/* <div className="w-full  rounded-full h-2">
          <div
            className="bg-theme-blue h-2 rounded-full"
            style={{ width: `${now}%` }}
          ></div>
        </div> */}

        <div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-200 float-left">
          <div
            class="bg-theme-blue h-2 rounded-full"
            style={{ width: `${now}%` }}
          ></div>
        </div>
      </m.section>
    );
  }
  useEffect(() => {
    return () => {
      slideNum = -1;
    };
  }, []);
  const clases =
    " full-screen overflow-hidden hero-header hero-calc " + className + " ";
  return (
    <div style={props.style}>
      <Helmet>
        <title>Jetzt Preis für Videoüberwachung berechnen</title>
        <meta
          name="description"
          content="Was kostet eine Videoüberwachungsanlage? Erhalten Sie in wenigen Minuten einen Preis für Ihre Videoüberwachung | Preis & Kosten Videoanlage berechnen!"
        />
        <meta
          name="keywords"
          content=": preis videoüberwachung, videoüberwachung preis, kosten videoanlage, videoanlage kosten, preis berechnen videoüberwachung, was kostet eine videoüberwachung"
        />
      </Helmet>

      <m.section
        className={embeded ? clases + " embeded-calc" : clases + " hero-image"}
        {...fadeIn}
      >
        <LazyLoadImage
          src={Ausgezeichnet}
          className="md:hidden block"
          style={{
            maxHeight: "60px",
            position: "absolute",
            right: "10px",
            top: "39px",
          }}
        />
        {(!embeded && Progress != 100) ||
        (!embeded && Progress == 100 && window.innerHeight > 800) ? (
          <h4 className=" font-semibold  text-center text-[#fff] mt-10  sm:hidden block ">
            Ihr kostenloser Videoüberwachungs-Preisrechner
          </h4>
        ) : (
          ""
        )}

        <div className=" h-full text-center justify-center ">
          <div
            className={
              shaddow
                ? " price-calculator box-shaddow-price-calc price-calculator-620-fixwidth  md:mx-auto"
                : "price-calculator mx-auto box-shaddow-price-calc"
            }
          >
            {PC_FLOW[Form.objectType].length - 2 == Slide ? (
              <div
                class="flex items-center p-3  text-lg text-white  bg-[#74af53]  "
                role="alert"
              >
                <i class="fa-solid fa-check mr-2"></i>

                <div>
                  Gute Nachricht! Der Preis für Ihre Anlage wird ermittelt.
                </div>
              </div>
            ) : (
              ""
            )}
            {PC_FLOW[Form.objectType].length - 1 == Slide ? (
              <div
                class="flex items-center p-4 mb-4 text-lg text-white  bg-[#74af53] "
                role="alert"
              >
                {/* <i className="fa fa-angle-down mr-2" /> */}
                <i class="fa-solid fa-check"></i>
                <div> &nbsp;Herzlichen Dank für Ihre Preisanfrage!</div>
              </div>
            ) : (
              ""
            )}
            <div className="p-8 padding-399 padding-05">
              {Progress == 100 ? (
                ""
              ) : (
                <div className="progress-bar">{<ProgressBarCom />}</div>
              )}
              <div className="pc-questions fade-in-right" {...fadeInLeft}>
                {" "}
                {/* animation={fadeInLeft} */}
                {/* <AnimatePresence> */}
                <VideoPriceCalculatorOutlet />
                {/* </AnimatePresence> */}
              </div>
              <div className="pc-footer mt-20 block  mt-2rem ">
                <div className="d-none d-md-block">
                  <button
                    type="button"
                    class="float-left button-left px-4 py-3.5 text-sm text-gray-700 "
                    onClick={prevPriceCard}
                    hidden={Slide === -1 || Progress == 100 ? true : false}
                  >
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    {/* <span> Back</span> */}
                  </button>

                  <button
                    type="button"
                    class="px-16 py-2.5 text-white bg-[#1895ff] hover:bg-[#1486e9]   font-medium float-right "
                    onClick={nextPriceCard}
                    hidden={
                      PC_FLOW[Form.objectType].length - 2 == Slide ||
                      PC_FLOW[Form.objectType].length - 1 == Slide
                    }
                    disabled={!displayDonKnow}
                  >
                    Weiter
                  </button>
                  {displayDonKnow ? (
                    <button
                      type="button"
                      class="text-gray-900 bg-white border float-right border-gray-300   font-medium  text-sm px-8 py-3.5   ml-2 mr-3"
                      onClick={nextPriceCard}
                    >
                      Weiß nicht
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-block d-md-none">
                  <button
                    type="button"
                    class="float-left button-left px-4 py-3.5 text-sm text-gray-700 "
                    onClick={prevPriceCard}
                    hidden={!displayDonKnow}
                  >
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    {/* <span> Back</span> */}
                  </button>

                  <button
                    type="button"
                    class="px-16 py-2.5 text-white bg-[#1895ff] hover:bg-[#1486e9]   font-medium float-right "
                    onClick={nextPriceCard}
                    hidden={!displayDonKnow}
                  >
                    Weiter
                  </button>
                  {displayDonKnow ? (
                    <button
                      type="button"
                      class="text-gray-900 bg-white border float-right border-gray-300   font-medium  text-sm px-8 py-3.5   ml-2 mr-3"
                      onClick={nextPriceCard}
                    >
                      Weiß nicht
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={trusSymbolClases()}>
                <h3
                  style={{
                    fontSize: 16,
                    marginBottom: "10px",
                    textAlign: "center",
                    padding: "0",
                  }}
                  className="d-block d-md-none"
                >
                  Ausgezeichnet durch:
                </h3>
                <div className="pc-footer-trust-symbols">
                  <h3
                    style={{
                      fontSize: 16,
                      marginBottom: 10,
                      textAlign: "center",
                      padding: "0",
                    }}
                    className="d-none d-md-block"
                  >
                    Ausgezeichnet <br />
                    durch:
                  </h3>

                  <LazyLoadImage
                    src={TrustGoogle}
                    className="pc-footer-trust-symbols-img-1"
                    // style={{ marginLeft: 10, height: 65 }}
                  />
                  <LazyLoadImage
                    className="pc-footer-trust-symbols-img-2"
                    src={TrustMeisterbetrieb}
                    // style={{ marginLeft: 20, height: 65 }}
                  />
                  <LazyLoadImage
                    className="pc-footer-trust-symbols-img-3"
                    src={TrustAusgezeichnet}
                    // style={{ marginLeft: 20, height: 65 }}
                  />
                </div>
                {isThankYou ? (
                  <>
                    <div
                      className="ml-4  p-2 d-block d-md-none "
                      style={{
                        backgroundColor: "rgb(242, 242, 242)",
                        padding: "0.9rem",
                        minHeight: "50px",
                        verticalAlign: "middle",
                        margin: "12px 0px",
                        marginTop: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      <i
                        class="fas fa-piggy-bank   fa-piggy-custom"
                        style={{
                          color: "rgb(24, 149, 255)",
                          verticalAlign: "middle",
                          fontSize: "2.5rem",
                          // float: "left",
                        }}
                      ></i>
                      <p
                        style={{
                          lineHeight: "1.7rem !important",
                          fontSize: "16px",
                        }}
                      >
                        Jetzt noch für kurze Zeit:{" "}
                        <span className="font-bold"> -10% Sommer-Rabatt</span>{" "}
                        auf unsere Sicherheitstechnik
                      </p>
                    </div>
                    {/* <div className="grid grid-cols-2 d-block d-md-none mt-5">
                      <LazyLoadImage  src={CHECKOUT1} style={{ maxWidth: "20rem" }} />
                      <LazyLoadImage
                        src={CHECKOUT2}
                        className="mt-2 mb-2"
                        style={{ maxWidth: "20rem" }}
                      />
                    </div> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </m.section>
    </div>
  );
};

function PropertiesList(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section id="PR_CHECKIN">
      <h3>{PC_DATA.properties.question}</h3>
      <div className="grid grid-cols-4  place-items-center place-content-center  sm:grid-cols-1 gap-4 sm:gap-2 ">
        {PC_DATA.properties.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.properties.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.properties.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function WhichAreaWantToSecure(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section>
      <h3>{PC_DATA.WhichAreaWantToSecure.question}</h3>
      <div className="grid grid-cols-4     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.WhichAreaWantToSecure.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.WhichAreaWantToSecure.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.WhichAreaWantToSecure.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}

function HowManyCameras(props) {
  const { handleSelectChange, Form, nextPriceCard } = props;
  const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  return (
    <m.section>
      <h3>{PC_DATA.HowManyCameras.question}</h3>
      <div>
        <span className="font-bold">Videokameras:</span>
        <select
          name={PC_DATA.HowManyCameras.name}
          defaultValue={1}
          onChange={handleSelectChange}
          value={Form[PC_DATA.HowManyCameras.name]}
          class="bg-gray-50 border  ml-8 border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
        >
          <option selected>Videokameras</option>

          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>

        {/* <button
          type="button"
          class="text-gray-900 bg-white border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ml-2 "
          onClick={nextPriceCard}
        >
          Weiß nicht
        </button> */}
      </div>
    </m.section>
  );
}

function WhichCameraShape(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section>
      <h3>{PC_DATA.WhichCameraShape.question}</h3>
      <div className="grid grid-cols-4     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.WhichCameraShape.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.WhichCameraShape.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.WhichCameraShape.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function CameraResultion(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section>
      <h3>{PC_DATA.CameraResultion.question}</h3>
      <div className="grid grid-cols-4     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.CameraResultion.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.CameraResultion.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.CameraResultion.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function ShouldArtificialIntelligence(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section>
      <h3 className="mb-0 pb-0">
        {PC_DATA.ShouldArtificialIntelligence.question}
      </h3>
      <p className="mb-6">
        {PC_DATA.ShouldArtificialIntelligence.questionInfo}
      </p>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.ShouldArtificialIntelligence.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.ShouldArtificialIntelligence.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.ShouldArtificialIntelligence.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function AccessViaSmartPhone(props) {
  const { handleInputChange, Form } = props;

  return (
    <m.section>
      <h3>{PC_DATA.AccessViaSmartPhone.question}</h3>
      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.AccessViaSmartPhone.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.AccessViaSmartPhone.name] === option.value
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() =>
                handleInputChange({
                  name: PC_DATA.AccessViaSmartPhone.name,
                  value: option.value,
                })
              }
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function Extentions(props) {
  const { handleInputChange, Form, setForm, nextPriceCard } = props;
  function pushToCrossSellingArray(item) {
    const arr = Form[PC_DATA.Extentions.name];
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(item);
    }
    setForm({ ...Form, ...{ crossSelling: arr } });
  }
  return (
    <m.section>
      <h3 className="mb-0 pb-0">{PC_DATA.Extentions.question}</h3>
      <p className="mb-6 ">{PC_DATA.Extentions.questionInfo}</p>

      <div className="grid grid-cols-3     sm:grid-cols-1 gap-4 sm:gap-2 place-items-center place-content-center">
        {PC_DATA.Extentions.options.map((option) => {
          return (
            <div
              className={
                Form[PC_DATA.Extentions.name].includes(option.value)
                  ? " card  pc-card grow-animate pc-card-active"
                  : "card  pc-card grow-animate "
              }
              key={option.name}
              style={{ width: "100%" }}
              onClick={() => pushToCrossSellingArray(option.value)}
            >
              <div className="card-body">
                {/* <span className="card-title"> */}
                <LazyLoadImage
                  src={option.img}
                  className="price-calc-icon sm:float-left"
                />
                <span className="hidden sm:block sm:float-left sm:align-middle mt-[1.4rem] font-medium text-xlg ">
                  {option.name}
                </span>
                {/* </span> */}
              </div>
              <div className="card-footer pc-caption block sm:hidden">
                {option.name}
              </div>
            </div>
          );
        })}
      </div>
    </m.section>
  );
}
function UserInfoForm(props) {
  const { Form, nextPriceCard } = props;
  const inputClasses =
    " w-full rounded-md  py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 mb-3 ";
  const errorClasses = " !border-2 !border-red-500";
  const extraKeys = {
    // cctv: "ungefragt",
    // cctv_count: "ungefragt",
    // cctv_resolution: "ungefragt",
    // cctv_type: "ungefragt",
    // securitySystem: "Einbruch",
    fireAlarmAlreadyEquiped: "ungefragt",
    fireAlarmCarryProcess: "ungefragt",
    fireAlarmFloors: "ungefragt",
    fireAlarmRealEstateSize: "ungefragt",
    fireAlarmRequirements: "ungefragt",
    // crossSelling: [],
  };

  const [User, setUser] = useState({});
  phone = User.phone;
  const [ErrorsUser, setErrorUser] = useState({
    email: true,
    phone: true,
    firstName: true,
    lastName: true,
    salutation: true,
  });
  const [formSubmitted, setFormSubmittedX] = useState(false);

  function submitCalculatorForm(e) {
    e.preventDefault();
    console.log("submitCalculatorForm fired", formSubmitted);
    setFormSubmittedX(1);

    if (
      ErrorsUser.email ||
      ErrorsUser.firstName ||
      ErrorsUser.lastName ||
      ErrorsUser.phone ||
      ErrorsUser.salutation
    ) {
      console.log("err");
      return;
    }
    fetch(API_URL + "/checkout", {
      // fetch(`https://api.ma-development.de` + "/checkout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...Form, ...User, ...extraKeys }),
    }).then((response) => {
      console.log("Response FROM /CHECKOUT", response);
      nextPriceCard();
    });
  }

  function handleInputUserChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setUser({
      ...User,
      ...{
        [name]: value,
      },
    });
    console.log("user is", User);
    console.log("error is", ErrorsUser);
    if (name == "email") {
      let isError = true;
      if (
        String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, email: isError });
    }
    if (name == "phone") {
      let isError = true;
      if (value && value.length > 3) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, phone: isError });
    }
    if (name == "firstName") {
      let isError = true;
      if (value && value.length > 1 && value.length <= 30) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, firstName: isError });
    }
    if (name == "lastName") {
      let isError = true;
      if (value && value.length > 1 && value.length <= 30) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, lastName: isError });
    }
    if (name == "salutation") {
      let isError = true;
      if (value) {
        isError = false;
      }
      setErrorUser({ ...ErrorsUser, salutation: isError });
    }
  }
  return (
    <m.section>
      <h3
        style={{
          padding: "0",
        }}
      >
        Wer soll die kostenlose Preiskalkulation erhalten?
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-1 ">
        <div>
          <div class="flex items-center mb-4">
            <input
              id="gender1"
              type="radio"
              value="herr"
              name="salutation"
              class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleInputUserChange}
              checked={User.salutation == "herr"}
            />
            <label
              for="gender1"
              className={
                formSubmitted && ErrorsUser.salutation
                  ? "ms-2  font-medium text-lg text-red-500"
                  : "ms-2  font-medium text-lg text-dark-grey"
              }
            >
              Herr
            </label>
            <input
              id="gender2"
              type="radio"
              value="frau"
              name="salutation"
              class="w-6 h-6 ml-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleInputUserChange}
              checked={User.salutation == "frau"}
            />
            <label
              for="gender2"
              className={
                formSubmitted && ErrorsUser.salutation
                  ? "ms-2  font-medium text-lg text-red-500"
                  : "ms-2  font-medium text-lg text-dark-grey"
              }
            >
              Frau
            </label>
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            {" "}
            <input
              type="text"
              name="firstName"
              placeholder="Vorname"
              className={
                formSubmitted && ErrorsUser.firstName
                  ? inputClasses + errorClasses
                  : inputClasses
              }
              onChange={handleInputUserChange}
              value={User.firstName}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Nachname"
              className={
                formSubmitted && ErrorsUser.lastName
                  ? inputClasses + errorClasses
                  : inputClasses
              }
              onChange={handleInputUserChange}
              value={User.lastName}
            />
          </div>
          <input
            type="text"
            name="email"
            placeholder="E-Mail"
            className={
              formSubmitted && ErrorsUser.email
                ? inputClasses + errorClasses
                : inputClasses
            }
            onChange={handleInputUserChange}
            value={User.email}
          />
          <input
            type="number"
            name="phone"
            placeholder="Telefon"
            className={
              formSubmitted && ErrorsUser.phone
                ? inputClasses + errorClasses
                : inputClasses
            }
            onChange={handleInputUserChange}
            value={User.phone}
          />
          <div className="text-xs mt-3 text-grey text-[#777f8e] text-left ">
            <i class="fas fa-lock mr-1"></i>
            Die Sicherheit Ihrer Daten ist uns wichtig! Ihre Daten werden streng
            vertraulich behandelt und nicht an Dritte weitergegeben.
          </div>
          <Buttons
            className="btn-fill mr-[10px] font-bold mt-4 block  "
            themeColor="#1895FF"
            color="#fff"
            size="lg"
            title="Preis berechnen"
            onClick={submitCalculatorForm}
          />
        </div>
        {/* <div className="grid grid-cols-2 d-block d-md-none mt-5">
          <LazyLoadImage  src={CHECKOUT1} style={{ maxWidth: "20rem" }} />
          <LazyLoadImage
            src={CHECKOUT2}
            className="mt-2 mb-2"
            style={{ maxWidth: "20rem" }}
          />
        </div> */}
        <div className="grid grid-cols-2 sm:grid-cols-1">
          <div className="ml-6 d-none d-md-block">
            <b className="text-xs">Preiskalkulation (Beispiel):</b>
            <LazyLoadImage src={CHECKOUT1} />
          </div>
          <div>
            <span className="d-none d-md-block">
              <b className="text-xs">Gefördert durch:</b>
              <LazyLoadImage
                src={CHECKOUT2}
                className="p-2  ml-12"
                style={{ height: "100px", display: "block" }}
              />
            </span>
            <div
              className="ml-4  p-2 d-none d-md-block"
              style={{
                backgroundColor: "rgb(242, 242, 242)",
                padding: "0.9rem",
                maxHeight: "125px",
                minHeight: "124px",
              }}
            >
              <i
                class="fas fa-piggy-bank  block fa-piggy-custom"
                style={{
                  color: "rgb(24, 149, 255)",
                  verticalAlign: "middle",
                  fontSize: "3rem",
                }}
              ></i>
              <p
                className="text-sm"
                style={{ lineHeight: "1.7rem !important" }}
              >
                Jetzt noch für kurze Zeit:{" "}
                <span className="font-bold"> -10% Sommer-Rabatt</span> auf
                unsere Sicherheitstechnik
              </p>
            </div>
          </div>
        </div>
      </div>
    </m.section>
  );
}

function ThankYou(props) {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "THANKYOU_REACHED" });
      window.dataLayer.push({ event: "VIDEO_LEAD_TIRGGER" });
    } else {
      console.log("window.dataLayer not created");
    }
  }, []);
  return (
    <m.section>
      <div style={{ fontSize: "20px" }} id="VIDEO_LEAD_TIRGGER">
        <LazyLoadImage src={THANK_YOU} height="100px" width="100px" />

        <b className="block mt-4 mb-4 text-dark-grey">Wie geht es weiter?</b>

        <p>
          Ein MeinAlarm24 Sicherheitsberater berechnet aktuell einen ersten
          Preis für Ihr Projekt und wird sich bei offen gebliebenen Fragen unter
          der folgenden Telefonnummer bei Ihnen melden:
        </p>
        <br />
        <b className="text-dark-grey">{phone}</b>
      </div>
    </m.section>
  );
}

export default VideoPriceCalculator;
