import React, { useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { Link as ScrollTo } from "react-scroll";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ProcessStep from "../../Components/ProcessStep/ProcessStep";
import YT_VIDEO_IMAGE from "../../Assets/img/Kunden-werben-Kunden/01-3Steps-Kunden-werben-Kunden-Picture.webp";

// Data
import { ProcessStepData03 } from "../../Components/ProcessStep/ProcessStepData";

import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from "../../Components/Button/Buttons";
import PriceCaclculatorEmbeded from "../../Components/PriceCalculatorEmbeded/PriceCaclculatorEmbeded";

const Kundenwerbenkunden = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <Helmet>
        <title>Das MeinAlarm24-Kunden-werben-Kunden-Programm</title>
        <meta
          name="description"
          content="Sichern Sie sich bis zu 1.000€ Prämie mit dem MeinAlarm24-Kunden-werben-Kunden Programm! So einfach geht's: In nur 3 Schritten zu Ihrer Prämie.  "
        />
        <meta
          name="keywords"
          content="kunden-werben-kunden, kunden-werben-kunden meinalarm24, meinalarm24 kunden-werben-kunden"
        />
      </Helmet>

      <div className="full-screen md:flex md:items-center overflow-hidden relative sm:h-[500px]">
        <Parallax
          className="lg-no-parallax bg-cover absolute top-[0px] left-0 w-full h-[100vh]
          kunden-werben-kunden-bg
          "
          translateY={[-40, 40]}
        ></Parallax>
        <Container fluid className="relative">
          <Row className="h-[100vh] items-center">
            <Col
              xl={5}
              lg={{ span: 6, offset: 1 }}
              md={8}
              sm={10}
              className="h-full flex flex-col justify-center md:pl-[35px] xs:pl-[15px]"
            >
              <h1 className="  z-[1] font-semibold text-white  w-[80%] mb-0 xl:w-full   xs:w-full">
                Das MeinAlarm24 Kunden-werben-Kunden Programm
              </h1>
              <div
                className=" font-semibold  "
                style={{ fontSize: "32px", color: "#E50051" }}
              >
                Bis zu 1.000,- EUR Prämie
              </div>
              <div className="inline-block z-[1] mt-12">
                <span className="inline-block">
                  <ScrollTo
                    to="price_calc_scroll"
                    offset={0}
                    delay={0}
                    spy={true}
                    smooth={true}
                    duration={800}
                  >
                    <Buttons
                      ariaLabel="button"
                      href="#"
                      className="btn-fill   font-bold  rounded-2xl mt-4 mr-6"
                      themeColor="#1895FF"
                      color="#fff"
                      size="lg"
                      title="Jetzt Prämie sichern!"
                    />
                    <Buttons
                      ariaLabel="button"
                      href="#"
                      className="btn-fill mehr-erfahren-btn mt-4  font-bold  rounded-2xl  "
                      // themeColor="#1895FF"
                      // color="#1895FF"
                      size="lg"
                      title="Jetzt weiterempfehlen!"
                    />
                  </ScrollTo>
                </span>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section
        className="pt-32 overflow-hidden cover-background "
        // style={{
        //   backgroundImage: `url('/assets/img/webp/home-consulting-about-bg.webp')`,
        // }}
      >
        <Container>
          <div className="md:text-center hidden md:block">
            <h2 className="h2-header ">
              So einfach geht’s: Weiterempfehlen und Prämie sichern!
            </h2>
            <m.h5 className=" h5-sub-header">
              Bis zu 1.000,- EUR Prämie sichern.
            </m.h5>
          </div>
          <Row className="items-center justify-center  ">
            <m.div
              className="col-lg-6 col-md-12 md:mb-20 consulting-about"
              {...fadeIn}
            >
              <div className=" block md:hidden">
                <h2 className="h2-header ">
                  So einfach geht’s: Weiterempfehlen und Prämie sichern!
                </h2>
                <m.h5 className=" h5-sub-header">
                  Bis zu 1.000,- EUR Prämie sichern.
                </m.h5>
              </div>
              <ProcessStep
                grid="row-cols-1"
                theme="process-step-style-02"
                className=""
                data={ProcessStepData03}
                animation={fadeIn}
              />
            </m.div>
            <m.div className="col-lg-6 col-md-12" {...fadeIn}>
              <div className="">
                <div className="bg-darkslateblue  h-full w-full top-0 left-0 opacity-20"></div>
                <LazyLoadImage
                  className="w-full"
                  alt="Person empfiehlt MeinAlarm24 weiter"
                  src={YT_VIDEO_IMAGE}
                  width="457.5px"
                  height="607.66px"
                  // style={{ position: "relative" }}
                />
                {/* <div className="absolute bottom-6 left-44"> */}
                {/* <div className="absolute bottom-6 left-44"> */}
                <div className="flex items-center justify-center mt-[-80px] pb- ">
                  <ScrollTo
                    to="price_calc_scroll"
                    offset={0}
                    delay={0}
                    spy={true}
                    smooth={true}
                    duration={800}
                  >
                    <Buttons
                      ariaLabel="button"
                      className="btn-fill  font-bold  rounded-2xl  flex justify-center   "
                      themeColor="#1895FF"
                      color="#fff"
                      size="lg"
                      title="Jetzt weiterempfehlen"
                    />
                  </ScrollTo>
                </div>
              </div>
            </m.div>
          </Row>
        </Container>
      </section>
      <span className="price_calc_scroll"></span>
      <div className="mt-24">
        <PriceCaclculatorEmbeded
          type="kunden_wer_kunden"
          h1="Jetzt Prämie für sich oder einen Geworbenen sicher "
          h2="Herzlichen Glückwunsch! Sie sind nur noch einen Schritt davon entfernt, sich Ihre exklusive MeinAlarm24 “Kunden-werben-Kunden” Prämie i.H.v. bis zu 1.000€ zu sichern!"
          h3="Bitte tragen Sie hier die Kontaktdaten des Geworbenen ein, um alle Vorteile zu sichern!"
          className="kunden_werb"
        />
      </div>
    </>
  );
};

export default Kundenwerbenkunden;
