import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { m } from "framer-motion";
import { Helmet } from "react-helmet";

import Buttons from "@Components/Button/Buttons";
import { fadeIn } from "../../Functions/GlobalAnimations";
import CHECKOUT1 from "../../Assets/img/price_calc/210527 Screenshot Sicherheitskonzept.webp";
import CHECKOUT2 from "../../Assets/img/price_calc/220322_DIN_Logo.webp";
import THANK_YOU from "../../Assets/img/price_calc/image_2_kostenlose_beratung.svg";
import { useSearchParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const API_URL = "https://api.ma-development.de";
let phone = 0;
const Checkout = (props) => {
  const { technicalSource, marketingSource } = props;
  const swiperRef = React.useRef(null);
  const [pinCode, setPincode] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [result, setresult] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // console.log("searchParams.get()", searchParams.get("postalCode"));
  }, []);

  function UserInfoForm(props) {
    const { Form, nextPriceCard } = props;
    const inputClasses =
      " w-full rounded-md  py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 mb-3 ";
    const errorClasses = " !border-2 !border-red-500";
    const extraKeys = {
      technicalSource,
      marketingSource,
      // postalCode: searchParams.get("postalCode"),
      // special: searchParams.get("postalCode"),
    };

    const [User, setUser] = useState({});
    phone = User.phone;
    const [ErrorsUser, setErrorUser] = useState({
      email: true,
      phone: true,
      firstName: true,
      lastName: true,
      salutation: true,
    });
    const [formSubmitted, setFormSubmittedX] = useState(false);

    function submitCalculatorForm(e) {
      e.preventDefault();
      console.log("submitCalculatorForm fired", formSubmitted);
      setFormSubmittedX(1);

      if (
        ErrorsUser.email ||
        ErrorsUser.firstName ||
        ErrorsUser.lastName ||
        ErrorsUser.phone ||
        ErrorsUser.salutation
      ) {
        console.log("err");
        return;
      }
      fetch(API_URL + "/landing", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...Form, ...User, ...extraKeys }),
      }).then((response) => {
        console.log("Response FROM /CHECKOUT", response);
        setFormSubmitted(true);
      });
    }

    function handleInputUserChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      setUser({
        ...User,
        ...{
          [name]: value,
        },
      });
      console.log("user is", User);
      console.log("error is", ErrorsUser);
      if (name == "email") {
        let isError = true;
        if (
          String(value)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          isError = false;
        }
        setErrorUser({ ...ErrorsUser, email: isError });
      }
      if (name == "phone") {
        let isError = true;
        if (value && value.length > 3) {
          isError = false;
        }
        setErrorUser({ ...ErrorsUser, phone: isError });
      }
      if (name == "firstName") {
        let isError = true;
        if (value && value.length > 1 && value.length <= 30) {
          isError = false;
        }
        setErrorUser({ ...ErrorsUser, firstName: isError });
      }
      if (name == "lastName") {
        let isError = true;
        if (value && value.length > 1 && value.length <= 30) {
          isError = false;
        }
        setErrorUser({ ...ErrorsUser, lastName: isError });
      }
      if (name == "salutation") {
        let isError = true;
        if (value) {
          isError = false;
        }
        setErrorUser({ ...ErrorsUser, salutation: isError });
      }
    }
    return (
      <>
        <Helmet>
          <title>Risikoeinschätzung für Ihre Nachbarschaft erhalten!</title>
          <meta
            name="description"
            content="Erhalten Sie eine kostenlose und unverbindliche Auswertung über das Einbruchsrisiko in Ihrer Nachbarschaft. Jetzt kostenlos & unverbindlich anfordern!"
          />
          <meta
            name="keywords"
            content="sicherheit nachbarschaft, nachbarschaft sicher, risikoeinschätzung, einbrüche nachbarschaft, nachbarschaft einbrüche"
          />
        </Helmet>

        <div
          class="flex items-center p-3  text-lg text-white  bg-[#74af53]  "
          role="alert"
        >
          <i class="fa-solid fa-check mr-2"></i>

          <div>Gute Nachricht! Der Preis für Ihre Anlage wird ermittelt.</div>
        </div>
        <m.section className="bg-white p-6  ">
          <h3
            style={{
              padding: "0",
            }}
            className="h3-eintusion"
          >
            Wer soll die kostenlose Preiskalkulation erhalten?
          </h3>
          <div className="grid grid-cols-2 sm:grid-cols-1 ">
            <div>
              <div class="flex items-center mb-4">
                <input
                  id="gender1"
                  type="radio"
                  value="herr"
                  name="salutation"
                  class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={handleInputUserChange}
                  checked={User.salutation == "herr"}
                />
                <label
                  for="gender1"
                  className={
                    formSubmitted && ErrorsUser.salutation
                      ? "ms-2  font-medium text-lg text-red-500"
                      : "ms-2  font-medium text-lg text-dark-grey"
                  }
                >
                  Herr
                </label>
                <input
                  id="gender2"
                  type="radio"
                  value="frau"
                  name="salutation"
                  class="w-6 h-6 ml-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={handleInputUserChange}
                  checked={User.salutation == "frau"}
                />
                <label
                  for="gender2"
                  className={
                    formSubmitted && ErrorsUser.salutation
                      ? "ms-2  font-medium text-lg text-red-500"
                      : "ms-2  font-medium text-lg text-dark-grey"
                  }
                >
                  Frau
                </label>
              </div>
              <div className="grid grid-cols-2 gap-x-2">
                {" "}
                <input
                  type="text"
                  name="firstName"
                  placeholder="Vorname"
                  className={
                    formSubmitted && ErrorsUser.firstName
                      ? inputClasses + errorClasses
                      : inputClasses
                  }
                  onChange={handleInputUserChange}
                  value={User.firstName}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Nachname"
                  className={
                    formSubmitted && ErrorsUser.lastName
                      ? inputClasses + errorClasses
                      : inputClasses
                  }
                  onChange={handleInputUserChange}
                  value={User.lastName}
                />
              </div>
              <input
                type="text"
                name="email"
                placeholder="E-Mail"
                className={
                  formSubmitted && ErrorsUser.email
                    ? inputClasses + errorClasses
                    : inputClasses
                }
                onChange={handleInputUserChange}
                value={User.email}
              />
              <input
                type="number"
                name="phone"
                placeholder="Telefon"
                className={
                  formSubmitted && ErrorsUser.phone
                    ? inputClasses + errorClasses
                    : inputClasses
                }
                onChange={handleInputUserChange}
                value={User.phone}
              />
              <div className="text-xs mt-3 text-grey text-[#777f8e] text-left ">
                <i class="fas fa-lock mr-1"></i>
                Die Sicherheit Ihrer Daten ist uns wichtig! Ihre Daten werden
                streng vertraulich behandelt und nicht an Dritte weitergegeben.
              </div>
              <Buttons
                className="btn-fill mr-[10px] font-bold mt-4 block  "
                themeColor="#1895FF"
                color="#fff"
                size="lg"
                title="Preis berechnen"
                onClick={submitCalculatorForm}
              />
            </div>
            {/* <div className="grid grid-cols-2 d-block d-md-none mt-5">
          <img  src={CHECKOUT1} style={{ maxWidth: "20rem" }} />
          <img
            src={CHECKOUT2}
            className="mt-2 mb-2"
            style={{ maxWidth: "20rem" }}
          />
        </div> */}
            <div className="grid grid-cols-2 sm:grid-cols-1">
              <div className="ml-6 d-none d-md-block">
                <b className="text-xs">Preiskalkulation (Beispiel):</b>
                {/* <img src={CHECKOUT1} alt="img" /> */}
                <LazyLoadImage
                  alt="alt"
                  src={CHECKOUT1} // use normal <img> attributes as props
                />
              </div>
              <div>
                <span className="d-none d-md-block">
                  <b className="text-xs ">Gefördert durch:</b>
                  {/* <img
                    src={CHECKOUT2}
                    className="p-2  ml-12"
                    style={{ height: "100px", display: "block" }}
                    alt="img"
                  /> */}
                  <LazyLoadImage
                    src={CHECKOUT2}
                    className="p-2  ml-12"
                    style={{ height: "100px", display: "block" }}
                    alt="img"
                  />
                </span>
                <div
                  className="ml-4  p-2 d-none d-md-block"
                  style={{
                    backgroundColor: "rgb(242, 242, 242)",
                    padding: "0.9rem",
                    maxHeight: "125px",
                    minHeight: "124px",
                  }}
                >
                  <i
                    class="fas fa-piggy-bank  block fa-piggy-custom"
                    style={{
                      color: "rgb(24, 149, 255)",
                      verticalAlign: "middle",
                      fontSize: "3rem",
                    }}
                  ></i>
                  <p
                    className="text-sm text-dark-grey"
                    style={{ lineHeight: "1.7rem !important" }}
                  >
                    {/* start */}
                    Jetzt noch für kurze Zeit:{" "}
                    <span className="font-bold"> -10% Sommer-Rabatt</span> auf
                    unsere Sicherheitstechnik
                    {/* end */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </m.section>
      </>
    );
  }
  function ThankYou(props) {
    return (
      <m.section className="bg-white p-6  ">
        <div style={{ fontSize: "20px" }}>
          <LazyLoadImage
            src={THANK_YOU}
            height="100px"
            width="100px"
            style={{ margin: "0 auto" }}
          />

          <b className="block mt-4 mb-4 text-dark-grey">Wie geht es weiter?</b>

          <p>
            Ein MeinAlarm24 Sicherheitsberater berechnet aktuell einen ersten
            Preis für Ihr Projekt und wird sich bei offen gebliebenen Fragen
            unter der folgenden Telefonnummer bei Ihnen melden:
          </p>
          <br />
          <b className="text-dark-grey">{phone}</b>
        </div>
      </m.section>
    );
  }
  return (
    <div style={props.style}>
      {/* Section Start */}
      <m.section
        className="bg-darkblue-grey   hero-header embeded-calc2  "
        {...fadeIn}
      >
        <div className=" h-full text-center justify-center ">
          {/* <div className="contsiner-ein "> */}
          <div className=" ">
            <div className="pb-12">
              {/* <h1
                className=" font-semibold  text-center text-[#fff] mt-10 "
                style={{ fontSize: "35px" }}
              >
                Fordern Sie jetzt völlig{" "}
                <span className="text-pink">kostenlos</span> Ihre individuelle{" "}
                <span className="text-pink">Risikoeinschätzung</span> und
                Bestandsanalyse an
              </h1> */}
              <div
                style={
                  {
                    // maxWidth: "768px",
                  }
                }
                className="box-shaddow-price-calc price-calculator    price-calculator-620-fixwidth md:mx-auto md-ml-0 "
              >
                {!formSubmitted ? <UserInfoForm /> : ""}
                {formSubmitted ? <ThankYou /> : ""}
              </div>
            </div>
          </div>
        </div>
      </m.section>
    </div>
  );
};

export default Checkout;
