const ProcessStepData01 = [
  {
    icon: "line-icon-Idea-5",
    title: "Project Research",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Fountain-Pen",
    title: "Design Sketches",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Loading-2",
    title: "Selected Concept",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Juice",
    title: "Client Presentation",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
];

const ProcessStepData02 = [
  {
    title: "Preis berechnen",
    content:
      "Geben Sie die Informationen zu der Immobilie an, die Sie sichern möchten. Sie erhalten eine detaillierte Preiskalkulation für eine Anlage.",
  },
  {
    title: "Sicherheitskonzept abstimmen",
    content:
      "Ob telefonisch oder bei Ihnen vor Ort - unsere Sicherheitsberater stehen Ihnen bei allen Fragen rund um Ihre Sicherheit mit Rat und Tat zur Seite.",
  },
  {
    title: "Wir installieren die Anlage",
    content:
      "Lehnen Sie sich zurück und fühlen Sie sich sicher: Unser Team übernimmt auch die Installation Ihrer neuen Anlage mit eigenen Sicherheitstechnikern.",
  },
];

const ProcessStepData02new = [
  {
    title: "Preis berechnen",
    content:
      "Geben Sie die Informationen zu der Immobilie an, die Sie sichern möchten. Sie erhalten eine detaillierte Preiskalkulation für eine Alarmanlage.",
  },
  {
    title: "Sicherheitskonzept abstimmen",
    content:
      "Ob telefonisch oder bei Ihnen vor Ort - unsere Sicherheitsberater stehen Ihnen bei allen Fragen rund um Ihre Sicherheit mit Rat und Tat zur Seite.",
  },
  {
    title: "Wir installieren die Alarmanlage",
    content:
      "Lehnen Sie sich zurück und fühlen Sie sich sicher: Unser Team übernimmt auch die Installation Ihrer neuen Alarmanlage mit eigenen Sicherheitstechnikern.",
  },
];
const ProcessStepData07 = [
  {
    title: "Lokale Alarmierung",
    content:
      "Durch das Auslösen der Innen- und Außensirene werden Personen im Haus, aber auch Nachbarn alarmiert und der Einbrecher durch die akustischen Signale in die Flucht geschlagen",
  },
  {
    title: "Alarmierung der Bewohner",
    content:
      "Weiterhin wird sofort eine Push-Benachrichtigung auf alle Smartphones gesendet, auf denen die App eingerichtet ist",
  },
  {
    title: "Alarmierung zur Intervention",
    content:
      "Sofern die Alarmanlage aufgeschaltet ist, wird zusätzlich unsere Notrufleitstelle benachrichtigt, die dann einen festgelegten Plan verfolgt. Sollten Sie den Alarm nicht entschärfen, wird auch die Polizei oder unser Sicherheitsdienst informiert, um im Ernstfall keine Sekunde Zeit zu verlieren",
  },
];

const ProcessStepData03 = [
  {
    title: "Weiterempfehlen",
    content:
      "Sie sind mit der installierten Anlage zufrieden und empfehlen uns weiter.",
  },
  {
    title: "Beauftragung",
    content:
      "Ihr geworbener Kunde nimmt über www.meinalarm24.de/kunden- werben-kunden/ Kontakt zu uns auf und erwähnt vor der Beauftragung Ihren Namen.",
  },
  {
    title: "Prämie erhalten",
    content:
      "Der Geworbene erhält die Prämie in Form eines Rabattes direkt bei der Auftragserteilung. Sie erhalten nach Bezahlung der Anlage als Dankeschön Ihre Prämie in Form von einer Gutschrift oder einem Sachwert.",
  },
];
const ProcessStepData03new = [
  {
    title: "Sie empfehlen MeinAlarm24 weiter",
    content:
      "Stellen Sie MeinAlarm24 als professionellen Dienstleister vor und erläutern Sie die Vorteile des Partnerprogramms.",
  },
  {
    title: "Sie übermitteln die Kontaktdaten",
    content:
      "Übermitteln Sie die Kontaktdaten des Interessenten über Ihren persönlichen Link und bleiben Sie über jeden weiteren Schritt zwischen MeinAlarm24 und Ihrem Kunden informiert.",
  },
  {
    title: "Sie erhalten Ihre Provision",
    content:
      "Nach erfolgreicher Installation werden Sie automatisch per E-Mail über den finalen Kaufpreis informiert, so dass Sie uns eine Rechnung gemäß unserer Vereinbarung stellen können.",
  },
];

const ProcessStepData04 = [
  {
    title: "Preis berechnen",
    content:
      "Geben Sie die Informationen zu der Immobilie an, die Sie sichern möchten. Sie erhalten eine detaillierte Preiskalkulation für eine Videoanlage.",
  },
  {
    title: "Sicherheitskonzept abstimmen",
    content:
      "Ob telefonisch oder bei Ihnen vor Ort - unsere Sicherheitsberater stehen Ihnen bei allen Fragen rund um Ihre Sicherheit mit Rat und Tat zur Seite.",
  },
  {
    title: "Wir installieren die Videoanlage",
    content:
      "Lehnen Sie sich zurück und fühlen Sie sich sicher: Unser Team übernimmt auch die Installation Ihrer neuen Videoüberwachungsanlage mit eigenen Sicherheitstechnikern.",
  },
];
const ProcessStepData04new = [
  {
    title: "Preis berechnen",
    content:
      "Geben Sie die Informationen zu der Immobilie an, die Sie sichern möchten. Sie erhalten eine detaillierte Preiskalkulation für eine Schließanlage.",
  },
  {
    title: "Sicherheitskonzept abstimmen",
    content:
      "Ob telefonisch oder bei Ihnen vor Ort - unsere Sicherheitsberater stehen Ihnen bei allen Fragen rund um Ihre Sicherheit mit Rat und Tat zur Seite.",
  },
  {
    title: "Wir installieren die Schließanlage",
    content:
      "Lehnen Sie sich zurück und fühlen Sie sich sicher: Unser Team übernimmt auch die Installation Ihrer neuen elektronischen Schließanlage  mit eigenen Sicherheitstechnikern.",
  },
];

const ProcessStepData05 = [
  {
    title: "Start market research",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Discussion of the idea",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Production planning",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
];

const ProcessStepData06 = [
  {
    title: "Innovative business",
    content: "Easy to customize",
  },
  {
    title: "Expertly marketing",
    content: "High quality services",
  },
  {
    title: "Engaging audiences",
    content: "Build perfect websites",
  },
];

export {
  ProcessStepData01,
  ProcessStepData02,
  ProcessStepData02new,
  ProcessStepData03,
  ProcessStepData03new,
  ProcessStepData04,
  ProcessStepData04new,
  ProcessStepData05,
  ProcessStepData06,
  ProcessStepData07,
};
